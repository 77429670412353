import { get, put } from "@/api/axios";

// 亲昵度规则设置-修改
export const update = params => put('/web/level/intimacy/update', params);

// 亲昵度规则设置-查看
export const view = () => get('/web/level/intimacy/view');




